import * as MyActions from './actions'
import { _SYNC_ITEM_S3 } from '../../dashboard/common'
import { commonSyncS3 } from '../commonInst'

const translate = (data) => { return {
    [MyActions.FIELDS.DESCRIPTION] : data.description,
    [MyActions.FIELDS.SCHEDULE] : data.scheduleExpression,
    [MyActions.FIELDS.STATE] : data.state,   // This state is whether enabled or disabled - not the endpoint running state

    [MyActions.FIELDS.DIMENSIONS] : data[MyActions.INSTRUMENT].dimensions,
    [MyActions.FIELDS.METRIC] : data[MyActions.INSTRUMENT].metric,
    [MyActions.FIELDS.NAMESPACE] : data[MyActions.INSTRUMENT].namespace,
    [MyActions.FIELDS.STAT] : data[MyActions.INSTRUMENT].stat,
    [MyActions.FIELDS.PERIOD] : data[MyActions.INSTRUMENT].period,
    
    [MyActions.FIELDS.TOLERANCE] : data[MyActions.INSTRUMENT].tolerance,
    [MyActions.FIELDS.GREATER_THAN] : data[MyActions.INSTRUMENT].greater_than,
    [MyActions.FIELDS.ALERT_START] : data[MyActions.INSTRUMENT].alert_start,
    [MyActions.FIELDS.ALERT_END] : data[MyActions.INSTRUMENT].alert_end,
}}

const handlers = {
    [MyActions.SAVE_DATA] : (state, action) => {
        return state
    },
    [_SYNC_ITEM_S3] : (state, action) => {

        // If got here data is specific for this instrument
        const { name, env, data } = action.payload

        return commonSyncS3(state, name, env, data, translate, MyActions.INSTRUMENT)
    },
    // ...
}


export default (state = {}, action) => {
	
	if (action.type in handlers) {
		return handlers[action.type](state,action);
    }
    return state;
}