export const BY_PASS = process.env.NODE_ENV !== 'production'

/******  TODO Only preliminary, same for S3_ACCESS - needs to be configured at runtime with temp credentials from STS - populate with login functionality (TODO) - cannot have credentials like this - static website - remove hardcoding *****/
/// Also, need login functionality - that also returns additionaly properties depending on env passed in, such as stage and s3_bucket and possible user specific settings
/* 
export const API_ACCESS = {
    invokeUrl: BY_PASS ?  'https://uoow3tucs9.execute-api.ap-southeast-2.amazonaws.com': // FOR localhost:3000 
                          'https://e8phxdrn22.execute-api.ap-southeast-2.amazonaws.com', // FOR S3 static website
    accessKey: 'AKIA2LUCJTXEWBVPS5OX', // REQUIRED
    secretKey: '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', // REQUIRED
    //sessionToken: 'SESSION_TOKEN', //OPTIONAL: If you are using temporary credentials you must include the session token
    apiKey: 'wYWHFit9VD3LJ9F2NuK5c8cK15mQLiRd6HQadlX4', // REQUIRED
    region: 'ap-southeast-2', // REQUIRED: The region where the API is deployed.
    //systemClockOffset: 0, // OPTIONAL: An offset value in milliseconds to apply to signing time
    retries: 3, // OPTIONAL: Number of times to retry before failing. Uses axon-retry plugin.
    retryCondition: (err) => { // OPTIONAL: Callback to further control if request should be retried.  Uses axon-retry plugin.
      return err.response && err.response.status === 500;
    }
}
*/
export const API_ACCESS = BY_PASS ? {
    invokeUrl: 'https://8gnp5wminl.execute-api.ap-southeast-2.amazonaws.com', // FOR S3 static website
    //accessKey: 'AKIA3DM6Y5GJBDUQ52NX', // REQUIRED
    //secretKey: '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', // REQUIRED
    accessKey: 'AKIA3DM6Y5GJGBCAJXVQ', // amsdashboard-dev-user
    secretKey: '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',
    //sessionToken: 'SESSION_TOKEN', //OPTIONAL: If you are using temporary credentials you must include the session token
    apiKey: 'ZHg88DgS6GaDyAjDDBS5nVmtcGS78sINL3HQ7de0', // REQUIRED
    region: 'ap-southeast-2', // REQUIRED: The region where the API is deployed.
    //systemClockOffset: 0, // OPTIONAL: An offset value in milliseconds to apply to signing time
    retries: 3, // OPTIONAL: Number of times to retry before failing. Uses axon-retry plugin.
    retryCondition: (err) => { // OPTIONAL: Callback to further control if request should be retried.  Uses axon-retry plugin.
      return err.response && err.response.status === 500;
    }
} : {
    //invokeUrl: 'https://e8phxdrn22.execute-api.ap-southeast-2.amazonaws.com', // FOR S3 static website
    invokeUrl: 'https://m4bxj0tdt2.execute-api.ap-southeast-2.amazonaws.com',
    accessKey: 'AKIA2LUCJTXEWBVPS5OX', // REQUIRED
    secretKey: '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', // REQUIRED
    //sessionToken: 'SESSION_TOKEN', //OPTIONAL: If you are using temporary credentials you must include the session token
    //apiKey: 'wYWHFit9VD3LJ9F2NuK5c8cK15mQLiRd6HQadlX4', // REQUIRED
    apiKey: 'oTQOpku5hma0mLLihxgG48A88Dr9aaIS3lRj4H71',
    region: 'ap-southeast-2', // REQUIRED: The region where the API is deployed.
    //systemClockOffset: 0, // OPTIONAL: An offset value in milliseconds to apply to signing time
    retries: 3, // OPTIONAL: Number of times to retry before failing. Uses axon-retry plugin.
    retryCondition: (err) => { // OPTIONAL: Callback to further control if request should be retried.  Uses axon-retry plugin.
      return err.response && err.response.status === 500;
    }
}

export const API_STAGE = 'v1'

export const S3_ACCESS = {
  region: API_ACCESS.region,
  accessKeyId: API_ACCESS.accessKey,
  secretAccessKey: API_ACCESS.secretKey
}

// S3 Bucket - this is unique for each environment and must be changed when deploying entire appsupport monitor in a new environment i.e. aws account
//export const S3_BUCKET = BY_PASS ? 'app-support-myer' : 'app-support-myer-test'
export const S3_BUCKET = BY_PASS ? 'app-support-myer' : 'app-support-myer-prod'


// This Dashboard application - what is looked up in TeamMemberAuthApps DDB Table
export const AUTH_APP = 'support-dashboard-web'
//export const AUTH_APP = 'support-dashboard-web-nonprod'  // NONPROD Azure 
