import AWS from 'aws-sdk'
import * as util from '../util'
import Instruments from '../components/instruments/instruments'
import { _SYNC_ITEM_S3} from '../components/dashboard/common'
import * as Config from '../config'
import * as Services from './services'
import { BY_PASS } from '../config'

export const FILTER_ACTION = 'FILTER_ACTION'

export const SYNC_DDB = 'sync-ddb'    // Sync with dynamo db table
export const SYNC_DDB_ERR = 'sync-ddb-err'    // Cannot sync with dynamo db
export const INSERT_DDB = 'insert-ddb'  // Insert an app or inspection
export const MODIFY_DDB = 'modify-ddb' 	// modify 		"
export const REMOVE_DDB = 'remove-ddb'  // remove 		"

export const SIGNED_IN = 'signed-in'

export const simpleAction = () => dispatch => {
    dispatch({
     type: 'SIMPLE_ACTION',
     payload: 'result_of_simple_action'
    })
}


export const toggleEditable = (dashboard) => dispatch => {
	console.log(dashboard)  // dashboard will only come through if referenced in here!!
    dispatch({
     type: 'TOGGLE_EDITABLE',
    })
}
   

export const changeMode = (mode) => dispatch => {
    dispatch({
     type: 'CHANGE_MODE',
     payload: mode
    })
}


export const filterAction = (filterMap) => dispatch  => {
    dispatch({
        type: FILTER_ACTION,
        payload: filterMap
    })
}

// If AWS credentials have expired - do a refresh 
export const signInRefresh = () => {
	return (dispatch, getState) => {
		/* Cannot use this, as cannot setup CORS for the login.microsoft.com domain - need to setup a custom domain or proxy, that we can configure CORS on
		const headers = {
			"Content-Type": "application/json"
		}
		const data = {
		}

		const axios = require('axios')
		axios.defaults.withCredentials = true;
		axios.get(`https://amsauth.test.myer-services.com.au/saml-access?app=${Config.AUTH_APP}&refresh=true`, {headers, data}).then( res => {
			console.log(res)

			dispatch(getUserAction(null, true))

		}).catch( err => {   // Refresh failed --- give up for now TODO have more retries
			console.log(err)

			// Remove signedin from URL
			var url = new URL(window.location.href);
			var search_params = new URLSearchParams(url.search); 
			search_params.set('signedin', 'false');
			url.search = search_params.toString();
			window.location=url.toString()
		})
		*/
		//window.location=`https://amsauth.test.myer-services.com.au/saml-access?app=${Config.AUTH_APP}&refresh=true`

		const filterMap = getState().dashboard.filterMap || { app: [], env: [], inst: [], open: {}}
		//window.location = `https://amsauth.test.myer-services.com.au/saml-access?app=${Config.AUTH_APP}&data=${btoa( JSON.stringify(filterMap))}&refresh=true`
		window.location = `https://auth.ams.myer.com.au/saml-access?app=${Config.AUTH_APP}&data=${btoa( JSON.stringify(filterMap))}&refresh=true`

	}
}


/************************************************** */
// Temporary - removes authentication to azur
// ONLY for local development - remove once application is
// in prod/S3
export const dangerous = (filterMap) => {
	return (dispatch, getState) => {
		dispatch({
			type: SIGNED_IN,
			payload: {}
		})
		Services.updateMobileApp(Config.API_ACCESS, {})
		initialise((arg) => dispatch(readS3(arg)))
		dispatch(readDDB(filterMap))
	}
}
/************************************************** */

// Get user and AWS temporary credentials - with GUID in the secured cookie
// If refresh is true then filterMap is not applicable - it is ignored
export const getUserAction = (filterMap, refresh = false) => {
	return (dispatch, getState) => {
		const headers = {
			"Content-Type": "application/json"
		}
		const data = {
		}

		const axios = require('axios')
		axios.defaults.withCredentials = true;
		//axios.get(`https://amsauth.test.myer-services.com.au/saml-get-user${refresh===true?'?refresh=true':''}`, {headers, data}).then( res => {
		axios.get(`https://auth.ams.myer.com.au/saml-get-user${refresh===true?'?refresh=true':''}`, {headers, data}).then( res => {
			console.log(res)

			dispatch({
				type: SIGNED_IN,
				payload: res.data
			})

			// Update credentials 
			//debugger;

			const user = res.data
			Services.updateAPIGatewayService({
				...Config.API_ACCESS,
				
				accessKey: user.aws.Credentials.AccessKeyId,
				secretKey: user.aws.Credentials.SecretAccessKey,
				sessionToken: user.aws.Credentials.SessionToken
				
			}, user)

			Services.updateAwsService({
				...Config.S3_ACCESS,
				accessKeyId: user.aws.Credentials.AccessKeyId,
				secretAccessKey: user.aws.Credentials.SecretAccessKey,
				sessionToken: user.aws.Credentials.SessionToken
			}, user)

			Services.updateMobileApp({
				...Config.API_ACCESS,
				accessKey: user.aws.Credentials.AccessKeyId,
				secretKey: user.aws.Credentials.SecretAccessKey,
				sessionToken: user.aws.Credentials.SessionToken
			}, user)

			if (!refresh) {
				initialise((arg) => dispatch(readS3(arg)))
				dispatch(readDDB(filterMap))
			}


		}).catch( err => {
			console.log(err)

			// Remove signedin from URL
			var url = new URL(window.location.href);
			var search_params = new URLSearchParams(url.search); 
			search_params.set('signedin', 'false');
			url.search = search_params.toString();
			window.location=url.toString()
		})
	}
}

export const signOutAction = () =>  {
	return (dispatch, getState) => {
		const headers = {
			"Content-Type": "application/json"
		}
		const data = {
		}
		//debugger;
		const axios = require('axios')
		axios.defaults.withCredentials = true;
		//axios.delete('https://amsauth.test.myer-services.com.au/saml-get-user', {headers, data}).then( res => {
		axios.delete('https://auth.ams.myer.com.au/saml-get-user', {headers, data}).then( res => {
			console.log(res)
		}).catch( err => console.log(err))

		// Will completely exit the app
		window.location='https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0'

	}
}


const scanTable = () => {

    var params = {
		TableName : "AppSupportMonitor",
	};

    //docClient.query(params, function(err, data) {
	return new Promise((resolve, reject) => {
		docClient.scan( params, (err,data) => {
			if (err) {
				reject("Unable to query. Error:\n" + JSON.stringify(err, undefined, 2));
			} else {
				resolve(data)
			}
		})
	}) 
}

const POLL_QUEUE = 500  // Poll queue timeout
const queue = [];
// Resolve when item avl. on the queue
function deque() {
	return new Promise(resolve => {
	  const timer = setInterval(() => {
		if (queue.length > 0) {
			clearInterval(timer)
			resolve(queue.pop())
		}
	  }, POLL_QUEUE)
	});
}
  
// Process the queue in the background (not really a backround thread - but utilises the javascript event loop)
// Runs continuously (asynchronously)
async function processQueue(action) {
	//console.log('calling');
	var tuple = await deque()

	// loop forever
	while (tuple) {
		//console.log('processing queue....'+JSON.stringify(tuple))

		// Read items config data from s3
		action(tuple)

		// expected output: 'resolved'
		tuple = await deque()
	}

}

var s3 = null 

export const readS3 = ({item, env}) => {
	return (dispatch, getState) => {
		s3.getObject({
			Bucket: `${Config.S3_BUCKET}/${Instruments.actions[item.instrument].S3_FOLDER}`,
			Key: `${item.FQN.replace(/ /g,'__')}..${env}.cfg`,
			VersionId: item.versionId,
		}, function(err, data) {
			if (err) {
				console.log(err, err.stack)
				// ...
			} else {
				//console.log(data)
				// Sync the app/inspection with s3
				dispatch({ type: _SYNC_ITEM_S3, payload: { name: item.FQN, env: item.Environment, instrument: item.instrument, data: JSON.parse(data.Body.toString('utf-8'))}})
			}
		});
	}
}

// Periodically scans the DDB table
// DynamoDB streams and web sockets is used to efficiently obtain DDB updates. 
// However gets the entire table every 1 hour, in case event(s) have been missed
const DDB_POLL_TIMEOUT = 1000*60 // 1 Min - changes in between are via websocket/streaming
var ddbTimer = null
var _filterMap = null

// Interrupt due to a filter change, or edit action
export const interrupt = (ddbAction, filterMap = _filterMap) => {
	clearTimeout(ddbTimer)
	// Clear queue
	while (queue.length) {
		queue.pop()
	}
	// Start up readDDB again
	ddbAction(filterMap)
}

// For accessing DDB fields that may not be defined
const safe = (field, type) => {
	if (field !== undefined) {
		return field[type]
	}
	return undefined
}


const startWebSocket = (filterMap, dispatch, getState) => {
	//let socket = new WebSocket(BY_PASS ? "wss://13csl1zztf.execute-api.ap-southeast-2.amazonaws.com/v1" : "wss://dpkqathfgh.execute-api.ap-southeast-2.amazonaws.com/prod");
	let socket = new WebSocket(BY_PASS ? "wss://13csl1zztf.execute-api.ap-southeast-2.amazonaws.com/v1" : "wss://5wfc8sks9c.execute-api.ap-southeast-2.amazonaws.com/v1");
	socket.onopen = function(e) {
		console.log("[open] Connection established");
	};
	  
	// Respond to events on websocket
	socket.onmessage = (event) => {
		try {
			const parsed = JSON.parse(event.data)

			// Keys
			const ddbEntry = parsed.dynamodb
			const name = ddbEntry.Keys.FQN.S
			const env = ddbEntry.Keys.Environment.S


			// Convert to same as scan DB format
			if (parsed.eventName === 'REMOVE') {
				dispatch({ type: REMOVE_DDB, payload: { name, env} })
			} else { 
				// MODIFY and INSERT
				const image = parsed.dynamodb.NewImage
				const item = {
					"FQN" : name,
					"name" : name,
					"Environment" : env,
					"env" : env,
					"metric" : safe(image.metric, 'S'),
					"instrument" : safe(image.instrument,'S'),
					"type" : safe(image.instrument,'S'),
					"state" : safe(image.state, 'N'),
					"timestamp" : safe(image.timestamp, 'S'),
					"versionId" : safe(image.versionId, 'S'),
				}

				if (filterMap===undefined || (
					(filterMap.app.length===0 || filterMap.app.includes(util.root(item.FQN))) &&
					(filterMap.env.length===0 || filterMap.env.includes(item.Environment))&&
					(filterMap.inst.length===0 || filterMap.inst.includes(item.instrument) || util.isRoot(name)))) {
						
						// Check if versionId has changed and push to queue for S3 update
						try { 
							if (parsed.eventName === 'INSERT' || (item.versionId !== undefined && util.traverse(getState().dashboard, item.FQN, item.Environment).versionId !== item.versionId)) { // S3 updated
								if (!util.isRoot(item.FQN))
									queue.push({item, env: env})
							}
						} catch (err) {
							console.warn("readDDB::onmessage - something went wrong")
						}

						dispatch({ type: parsed.eventName === 'MODIFY' ? MODIFY_DDB : INSERT_DDB , payload: item})
				}
			}				
		} catch (err) {
			console.warn("Something wrong with incoming event on websocket")
		}
	}
	  
	socket.onclose = function(event) {
		if (event.wasClean) {
		  console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
		} else {
		  // e.g. server process killed or network down
		  // event.code is usually 1006 in this case
		  console.log('[close] Connection died');
		}
		//alert("Websocket Closed - Reopening !!!")
		socket=null
		setTimeout(() => {
			startWebSocket(filterMap, dispatch, getState)
		}, 5000)
	};
	  
	socket.onerror = function(error) {
		  console.log(`[error] ${error.message}`);
	};
}




export const readDDB = (filterMap) => {
	_filterMap = filterMap // Store filterMap for interrupt

	return (dispatch, getState) => {
		//const apps = getState().dashboard.apps
		var filterData = { app:[], env: [], inst: []}
		const captureFilterData = (item) => {
			if (util.isRoot(item)) { // get env and app name
				if (!filterData.app.includes(item.FQN))
					filterData.app.push(item.FQN)
				if (!filterData.env.includes(item.Environment))
					filterData.env.push(item.Environment)
			} else {
				if (!filterData.inst.includes(item.instrument))
					filterData.inst.push(item.instrument)
			}
		}
		var mem = null // Keep data for resync with realtime websocket event data
		const processData = (data) => {
			try {
				mem = data
				// Add each changed entry in the table to a queue
				//	have async process reading the queue and performing the
				// s3 reads - and sync each entry
				const apps = data.Items.filter( item => {
					captureFilterData(item)
					return util.isRoot(item) && (filterMap===undefined || (
							(filterMap.app.length===0 || filterMap.app.includes(item.FQN)) &&
							(filterMap.env.length===0 || filterMap.env.includes(item.Environment))))
				})
				const children = []
				data.Items.forEach( item => {
					if (!util.isRoot(item) && (filterMap===undefined || (
							(filterMap.app.length===0 || filterMap.app.includes(util.root(item.FQN))) &&
							(filterMap.env.length===0 || filterMap.env.includes(item.Environment))&&
							(filterMap.inst.length===0 || filterMap.inst.includes(item.instrument))))) {
								const appName = util.root(item.FQN)
								//const env = util.findItemInList(apps, appName, item.Environment).Environment  // env always same as parents
								const env = util.find(apps, appName, item.Environment).Environment  // env always same as parents
								children.push({item: item, env: env})
					}

				})
				const updated = children.filter(({item}) => {
					// If item is not found - we have to add it, as it must be a new item
					try { 
						return item.timestamp !== undefined && util.traverse(getState().dashboard, item.FQN, item.Environment).timestamp !== item.timestamp
					} catch (e) {
						return true   // New item
					}
				})	

				updated.forEach(({item,env}) => {
					//console.log(`pushing to queue: ${item.FQN}`)
					if (item.instrument !== 'group') {
						queue.push({item, env})
					}
				});
	
				// Sync against the whole DDB table			
				dispatch({ type: SYNC_DDB, payload: { tbl: data, apps: apps, filterData: filterData, children: children}})

			} catch (err) {

				console.error("Could not process DDB data", err)
				dispatch({ type: SYNC_DDB_ERR, payload: err})
			}
		}
		const task = () => {
			scanTable().then(processData).catch((err) => {
				
				//debugger;
// TODO if due to expiry do a refresh --- if refresh count is not 0 --- successful refresh should reset the refresh count	
				dispatch(signInRefresh())  // If due to expiry - try to refresh AWS credentials
				dispatch({ type: SYNC_DDB_ERR, payload: err})
			})
		}

		// Streaming - delta updates
		startWebSocket(filterMap, dispatch, getState) 

		/*
		// Respond to events on websocket
		socket.onmessage = (event) => {
			try {
				const parsed = JSON.parse(event.data)

				// Keys
				const ddbEntry = parsed.dynamodb
				const name = ddbEntry.Keys.FQN.S
				const env = ddbEntry.Keys.Environment.S


				// Convert to same as scan DB format
				if (parsed.eventName === 'REMOVE') {
					dispatch({ type: REMOVE_DDB, payload: { name, env} })
				} else { 
					// MODIFY and INSERT
					const image = parsed.dynamodb.NewImage
					const item = {
						"FQN" : name,
						"name" : name,
						"Environment" : env,
						"env" : env,
						"metric" : safe(image.metric, 'S'),
						"instrument" : safe(image.instrument,'S'),
						"type" : safe(image.instrument,'S'),
						"state" : safe(image.state, 'N'),
						"timestamp" : safe(image.timestamp, 'S'),
						"versionId" : safe(image.versionId, 'S'),
					}

					if (filterMap===undefined || (
						(filterMap.app.length===0 || filterMap.app.includes(util.root(item.FQN))) &&
						(filterMap.env.length===0 || filterMap.env.includes(item.Environment))&&
						(filterMap.inst.length===0 || filterMap.inst.includes(item.instrument) || util.isRoot(name)))) {
							
							// Check if versionId has changed and push to queue for S3 update
							try { 
								if (parsed.eventName === 'INSERT' || (item.versionId !== undefined && util.traverse(getState().dashboard, item.FQN, item.Environment).versionId !== item.versionId)) { // S3 updated
									queue.push({item, env: env})
								}
							} catch (err) {
								console.warn("readDDB::onmessage - something went wrong")
							}

							dispatch({ type: parsed.eventName === 'MODIFY' ? MODIFY_DDB : INSERT_DDB , payload: item})
					}
				}				
			} catch (err) {
				console.warn("Something wrong with incoming event on websocket")
			}
		}
		*/

		// Run initially
		task()
		// Continue Polling
		var count = 5
		ddbTimer = setInterval( () => {
			/* Test refresh
			count--
			if (count === 0 ) {
				count = 5

				dispatch(signInRefresh())
			} else {
				task()
			}
			*/ 
			// else
			task()
		
		}, DDB_POLL_TIMEOUT)
	}
}



var docClient = null
export const initialise = (action) => {

		// S3/DynamoDB access
		/*
		AWS.config.update(Config.S3_ACCESS)

		docClient = new AWS.DynamoDB.DocumentClient()
		s3 = new AWS.S3()
		*/
		docClient = Services.Clients.DDB
		s3 = Services.Clients.S3

		// Listen for delta updates to DDB table
		//startWebSocket()

		// Process the queue in the background (not really a backround thread - but utilises the javascript event loop)
		// Runs continuously (asynchronously)
		processQueue(action);
}