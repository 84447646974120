import React, { Component } from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string'

import { AUTH_APP, BY_PASS } from './config'


//import logo from './logo.svg';
import logo from './images/logo_text.png';


import Dashboard from "./components/dashboard/dashboard"

import { simpleAction, toggleEditable, filterAction, interrupt, readDDB, readS3, signOutAction, getUserAction, dangerous, signInRefresh } from './services/actions'
import { Segment, Grid, Dropdown, Icon, Button} from 'semantic-ui-react';

import './App.css';
import { LabelInfo } from './components/basic/input/form';
import { info, GENERAL } from './components/dashboard/common'

const reload = (filterMap) => {
  //window.location = `https://amsauth.test.myer-services.com.au/saml-access?app=${AUTH_APP}&data=${btoa( JSON.stringify(filterMap))}`
  window.location = `https://auth.ams.myer.com.au/saml-access?app=${AUTH_APP}&data=${btoa( JSON.stringify(filterMap))}`
}

class App extends Component {
  constructor (props) {  
    super(props)
    const filterMap = props.filterMap // will exist if URL contains filter info
    this.state = { filter: filterMap ? 
        { app: filterMap.app, env: filterMap.env, inst: filterMap.inst, open: filterMap.open ? filterMap.open.qn : '', lastQueryString: filterMap.lastQueryString } :
        { app: [], env: [], inst: [], open: {}}
    }
    if (filterMap !== undefined) {
      props.filterAction(filterMap)
    }

    //debugger;

    /* TEMP!!!!!!!!!!!!!!!!!!! - Bypass auth TODO remove */
    if (BY_PASS) {
      props.bypass(filterMap)
    } else {
      if ( !props.signedinUrl ) {
        reload(this.state.filter)  // login
      } else { // try to sign in
        props.getUserAction(props.filterMap)
      }
    }
  }

  changeRoute() {
    const filter = this.state.filter
    const filtered = filter.app.length>0 || filter.env.length>0 || filter.inst.length>0
    if (filtered) {
      const query = '/filter?' +
        (filter.app.length>0 ? `app=${filter.app.join(',')}` : '') +
        (filter.env.length>0 ? `&env=${filter.env.join(',')}` : '') +
        (filter.inst.length>0 ? `&inst=${filter.inst.join(',')}` : '') +
        `&signedin=${this.props.signedinUrl}`
      this.props.history.push(query)
    } else {
      this.props.history.push(`/?signedin=${this.props.signedinUrl}`)
    }
  }

  getFilterOptions() {
    const filterData = this.props.dashboard.filterData
    const options = {}
    options['app'] = filterData.app.map( (item) => { return { key: item, text: item, value: item }})
    options['env'] = filterData.env.map( (item) => { return { key: item, text: item, value: item }})
    options['inst'] = filterData.inst.map( (item) => { return { key: item, text: item, value: item }})
    return options
  }

  render() {
    const options = this.getFilterOptions()
    const fMap = this.props.filterMap || {}
    const onChangeFilter = (filter, value) => {
      const clone = {...this.state}
      clone.filter[filter] = value
      this.setState(clone)
      this.props.filterAction(clone.filter)
      interrupt(this.props.readDDB, clone.filter)
      this.changeRoute()
    }
    const dashboard = this.props.dashboard
    return (
      <div className="App">
        <style dangerouslySetInnerHTML={{__html: `
          @media only screen and (max-device-width: 767px) and (orientation: portrait) {
              .ui.column.grid>[class*="five wide"].column, .ui.grid>.column.row>[class*="five wide"].column, .ui.grid>.row>[class*="five wide"].column, .ui.grid>[class*="five wide"].column {
                width: 100%!important;
                padding: 2px!important;
              }
              .bar > .ui.grid {
                width: 60%;
                display: inline-flex !important;
                padding:10px;
              }
              .bar.ui.segment {
                #min-height: 160px!important;
                margin: 10px!important;
              }
              .loader.content {
                padding: 10px!important;
              }
            }
        `}}></style>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <Segment className='bar' >
          <Icon style={{marginRight:'20px'}} name='filter' size='large'/>
          <Grid columns={4} style={{fontSize:'smaller'}}>
            <Grid.Column className='myPadding' width={5}>
              <Dropdown value={fMap.app ? fMap.app : []} onChange={
                (e, { value }) => {
                  onChangeFilter('app', value)
                }
              } placeholder='Applications' fluid multiple selection options={options.app} />
            </Grid.Column>
            <Grid.Column className='myPadding' width={5}>
              <Dropdown value={fMap.env ? fMap.env : []} onChange={
                (e, { value }) => {
                  onChangeFilter('env', value)
                }
              } placeholder='Environment' fluid multiple selection options={options.env} />
            </Grid.Column>
            <Grid.Column className='myPadding' width={5}>
              <Dropdown value={fMap.inst ? fMap.inst : []} onChange={
                (e, { value }) => {
                  onChangeFilter('inst', value)
                }
              } placeholder='Instrument' fluid multiple selection options={options.inst} />
            </Grid.Column>
          </Grid>
          { !this.props.main.signedIn ? 
            (<Button onClick={ () => reload(this.state.filter) } style={{float:'right',padding:'6px',margin:'0'}} icon className='right-align-edit-icon'>
              <Icon name='sign-in' size='large'/>
            </Button>) :
            (<Button onClick={() => { 
                  this.props.signOutAction()}
                } style={{float:'right',padding:'6px',margin:'0'}} icon className='right-align-edit-icon'>
              <Icon name='sign-out' size='large'/>
            </Button>)
          }
          <Button onClick={ () => {
              this.props.toggleEditable(dashboard)
            }} style={{float:'right',padding:'6px',marginRight:'3px'}} icon className='right-align-edit-icon'>
            <Icon name='edit outline' size='large'/>
          </Button>
          { this.props.main.isEditable ? <LabelInfo style={{float:'right', marginLeft:'10px', display:'inline!important', paddingTop: '6px'}} iconSize='large' >{info('Top', 'Bar').info}</LabelInfo> : null }
        </Segment>
        { this.props.main.signedIn ? (<Dashboard filterMap={this.props.filterMap} />) : null}
      </div>
    );
  }
}

const getOpenMap = (open) => {
  const a = open.split('::')
  return {
    name: a[0],
    env: a.length >=2 ? a[1] : null,
    inst: a.length >= 3 ? a[2] : null,
    qn: open   // Fully qualified name in original form
  }
}

const getFilterMap = (filter, queryString) => {
  //const filter = QueryString.parse(queryString)
  return {
    app: filter.app ? filter.app.split(',') : [],   // empty array means ALL
    env: filter.env ? filter.env.split(',') : [],
    inst: filter.inst ? filter.inst.split(',') : [],
    open: filter.open ? getOpenMap(filter.open) : undefined, 
    lastQueryString: queryString,
  }
}

const mapStateToProps = (state, ownProps)  => {
  const query = QueryString.parse(ownProps.location.search)
  const signedinUrl = query.signedin ? query.signedin === 'true' : true // Make default true. as we could already be signed in
                                                                        // If not, will set to false, so we do a signin
  return (ownProps.match.params.filter ? {
    ...state, filterMap: getFilterMap(query, ownProps.location.search), signedinUrl
  }: { ...state, signedinUrl })
}

 const mapDispatchToProps = dispatch => ({
  simpleAction: () => dispatch(simpleAction()),
  toggleEditable: (dashboard) => dispatch(toggleEditable(dashboard)),
  filterAction: (filterMap) => dispatch(filterAction(filterMap)),
  readDDB : (filterMap) => dispatch(readDDB(filterMap)),
  readS3 : (arg) => dispatch(readS3(arg)),
  signOutAction : () => dispatch(signOutAction()),
  getUserAction : (filterMap, refresh) => dispatch(getUserAction(filterMap, refresh)),
  bypass : (filterMap) => dispatch(dangerous(filterMap)),
  signInRefresh: () => dispatch(signInRefresh())
 })

 export default connect(mapStateToProps, mapDispatchToProps)(App);
