import React from 'react'
import { Segment, Form, Checkbox } from 'semantic-ui-react'
import { FormInput, FormTextArea, FormGroup} from '../../basic/input/form'
import * as Actions from './actions'
import * as Util from '../../../util'
import { error } from '../../dashboard/common'
import ScheduleForm from '../scheduleForm'

/* Stateless functional component - make class if require more advanced behaviour*/
export default (props => {
    const styles = { 
    };
    const { app, item, actions, dashboard} = props   // item is app - if root level, else inspection instance
    const data = item.data || {}
    // Merge with any data changed so far
    const level = Util.level(item.name)
    const save = dashboard.inspection.levels[level-1].save
    const merged = save ? { ...data, ...save.changed } : data
    const parent = Util.traverse(dashboard, Util.parentName(item.name), app.env)
    const set = (field, value) => actions.editInsp(parent, item, { att: field, value: value})  
    return (
        <div style = {{...styles}}>
           <ScheduleForm dashboard={dashboard} merged={merged} level={level} set={set} fields={Actions.FIELDS} />
            <Segment color='blue'>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormTextArea {...error(dashboard,Actions.FIELDS.DIMENSIONS, level)} style={{}} label='Dimensions' placeholder='Dimensions' value={merged[Actions.FIELDS.DIMENSIONS]} onChange={(e, { value }) => set(Actions.FIELDS.DIMENSIONS, value)}/>
                        <Segment.Group style={{margin:0, border:'none', boxShadow:'none', width:'40%'}}>
                            <FormInput {...error(dashboard,Actions.FIELDS.METRIC, level)} type='string' label='METRIC' placeholder='Metric' value={merged[Actions.FIELDS.METRIC]} onChange={(e, { value }) => set(Actions.FIELDS.METRIC, value)}n/>
                            <FormInput {...error(dashboard,Actions.FIELDS.NAMESPACE, level)} type='string' label='NAMESPACE' placeholder='Namespace' value={merged[Actions.FIELDS.NAMESPACE]} onChange={(e, { value }) => set(Actions.FIELDS.NAMESPACE, value)}n/>
                            <FormInput {...error(dashboard,Actions.FIELDS.STAT, level)} type='string' label='STAT' placeholder='Stat' value={merged[Actions.FIELDS.STAT]} onChange={(e, { value }) => set(Actions.FIELDS.STAT, value)}n/>
                            <FormInput {...error(dashboard,Actions.FIELDS.PERIOD, level)} type='string' label='PERIOD' placeholder='Period' value={merged[Actions.FIELDS.PERIOD]} onChange={(e, { value }) => set(Actions.FIELDS.PERIOD, value)}n/>
                        </Segment.Group>
                    </Form.Group>
                </Form>   
            </Segment>
            <Segment color='yellow'>
                <label>Tolerance</label>
                <Form>
                    <Form.Group style={{paddingTop:'20px'}} inline widths='equal'>
                        <FormInput {...error(dashboard,Actions.FIELDS.TOLERANCE, level)} type='number' label='Tolerance' placeholder='Tolerance to trigger alert' value={merged[Actions.FIELDS.TOLERANCE]} onChange={(e, { value }) => set(Actions.FIELDS.TOLERANCE, value)}n/>
                        <FormGroup style={{paddingRight: '50px'}} {...error(dashboard,Actions.FIELDS.SAVE_RESPONSE, level)}  >
                            <label style={{paddingRight: '20px'}} >Greater Than</label>
                            <Form.Radio label='Yes' value='YES' checked={merged[Actions.FIELDS.GREATER_THAN] === 'YES'} onChange={(e, { value }) => set(Actions.FIELDS.GREATER_THAN, value)}  />
                            <Form.Radio label='No' value='NO' checked={merged[Actions.FIELDS.GREATER_THAN] === 'NO'} onChange={(e, { value }) => set(Actions.FIELDS.GREATER_THAN, value)}  />
                        </FormGroup>
                        <Segment.Group style={{margin:0, border:'none', boxShadow:'none', width:'40%'}}>
                            <FormInput {...error(dashboard,Actions.FIELDS.ALERT_START, level)} type='string' label='ALERT START' placeholder='Alert Start' value={merged[Actions.FIELDS.ALERT_START]} onChange={(e, { value }) => set(Actions.FIELDS.ALERT_START, value)}n/>
                            <FormInput {...error(dashboard,Actions.FIELDS.ALERT_END, level)} type='string' label='ALERT END' placeholder='Alert End' value={merged[Actions.FIELDS.ALERT_END]} onChange={(e, { value }) => set(Actions.FIELDS.ALERT_END, value)}n/>
                        </Segment.Group>
                    </Form.Group>
                    
                </Form>   
            </Segment>
        </div>
    )
})