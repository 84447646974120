// TODO data will come from s3

import * as dashboardActions from './components/dashboard/actions'

export default { 
    isEditable: false,
    isLoading: true,
    apps: [   // Level 1
        { 
            name: 'Dummy', 
            env: 'Test',
            state: dashboardActions.STATES.GREEN,
            editState: dashboardActions.EDIT_STATES.START,
            mode: dashboardActions.MODES.VIEW,
            timestamp: 0,
            inspections: [   // Level 2
                {
                    state: dashboardActions.STATES.GREEN,
                    editState: dashboardActions.EDIT_STATES.START,
                    mode: dashboardActions.MODES.VIEW,
                    timestamp: 0,
                    name: 'Dummy.Login endpoint',
                    env: 'Test',
                    type: 'endpoint',
                    data: {"endpoint.method":"POST","endpoint.host":"ilpqutic68.execute-api.ap-southeast-2.amazonaws.com","endpoint.headers":"{\n        \"x-api-key\": \"Hvr7GjAtCvaJtisQhLiH51PImaYavOFr5XcUuIes\"\n}","endpoint.path":"/v1/search","aws-sign.type":"AWS","aws-sign.env":"prod","aws-sign.region":"ap-southeast-2","data.expected":"{\n    \"httpStatus\": 200\n}","data.body":"{\n      \"searchTerm\": \"Wednesday\",\n      \"limit\": 25,\n      \"userPrincipleName\": \"htaylor\"\n}","endpoint.scheme":"https"},
                    inspections: []    // Level 3
                },
                // ... more
            ],
        },
        { 
            name: 'Ticketing',
            env: 'Prod', 
            state: dashboardActions.STATES.RED,
            editState: dashboardActions.EDIT_STATES.START,
            mode: dashboardActions.MODES.VIEW,
            timestamp: 0,
            inspections: [   // Level 2
                {
                    state: dashboardActions.STATES.GREEN,
                    editState: dashboardActions.EDIT_STATES.START,
                    mode: dashboardActions.MODES.VIEW,
                    timestamp: 0,
                    name: 'Ticketing.Login endpoint',   // use to determine level
                    env: 'Prod',                        // env must be same as parents - all the way down hierarchy
                    type: 'endpoint',
                    data: {},
                    inspections: []    // Level 3
                },
                {   
                    state: dashboardActions.STATES.GREEN,
                    editState: dashboardActions.EDIT_STATES.START,
                    mode: dashboardActions.MODES.VIEW,
                    timestamp: 0,
                    name: 'Ticketing.Data endpoint',
                    env: 'Prod',
                    type: 'endpoint',
                    data: {},
                    inspections: []    // Level 3
                },
                // ... more
            ],
        },
        // ... more
    ],
}